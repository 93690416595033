import React from "react";

const changeDateFormat = (firstDateToChange, secondDateToChange) => {
  const firstDate = new Date(firstDateToChange);
  const firstDay = firstDate.toLocaleString("en-US", { day: "2-digit" });
  const firstMonth = firstDate
    .toLocaleString("en-US", { month: "long" })
    .toString()
    .slice(0, 3);

  const secondDate = new Date(secondDateToChange);
  const secondDay = secondDate.toLocaleString("en-US", { day: "2-digit" });
  const secondMonth = secondDate
    .toLocaleString("en-US", { month: "long" })
    .toString()
    .slice(0, 3);

  return (
    firstDay +
    " " +
    firstMonth +
    " " +
    "-" +
    " " +
    secondDay +
    " " +
    secondMonth
  );
};

export default changeDateFormat;

