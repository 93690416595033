import dayjs from "dayjs";
import { changeDaysjsFormat } from "./changeDaysjsFormat";

const Search = (table, search, tableName) => {
  let fileredByDepartment = [];
  let fileredByAddress = [];
  let filtredByAuthorisationDate = [];
  let filteredByStatus = [];
  let filteredByJobTitle = [];
  let filteredByName = [];
  let filtredByLeaveType = [];
  let filteredByNameLeavesTable = [];
  let filteredByNameAuthorisationTable = [];
  let filtredByDate = [];
  let Rows = [];
  Rows = table?.filter((row) => {
    return search == null
      ? row
      : (tableName === "leave-types" &&
          row.name
            .trim()
            .toLowerCase()
            .includes(search?.name?.trim().toLowerCase())) ||
        (tableName === "department" &&
          row.name
            .trim()
            .toLowerCase()
            .includes(search?.name?.trim().toLowerCase()))
      ? row
      : "";
  });

  // filter leaves table

  filtredByLeaveType = search?.leaveTypeID
    ? table?.filter((row) => {
        return search == null
          ? row
          : tableName === "leaves" || tableName === "leaves-history"
          ? row?.type?._id.includes(search?.leaveTypeID)
            ? row
            : ""
          : "";
      })
    : table;

  filtredByDate =
    search?.dateStart || search?.dateEnd
      ? filtredByLeaveType?.filter((row) => {
          const START = changeDaysjsFormat(search?.dateStart);
          const END = changeDaysjsFormat(search?.dateEnd);
          return search == null
            ? row
            : tableName === "leaves" || tableName === "leaves-history"
            ? dayjs(row?.dateStart).isBetween(dayjs(START), END) ||
              dayjs(row?.dateEnd).isBetween(dayjs(START), END) ||
              dayjs(row?.dateEnd).isSame(dayjs(START), END) ||
              dayjs(row?.dateEnd).isSame(dayjs(END), START)
              ? row
              : ""
            : "";
        })
      : search?.leaveTypeID
      ? filtredByLeaveType
      : search?.dateStart || search?.dateEnd
      ? filtredByDate
      : table;

  filteredByNameLeavesTable = search?.name
    ? filtredByDate?.filter((row) => {
        return search == null
          ? row
          : tableName === "leaves" || tableName === "leaves-history"
          ? row.user.fullName
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.name?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.dateStart || search?.dateEnd
    ? filtredByDate
    : search?.leaveTypeID
    ? filtredByLeaveType
    : table;

  //filter authorisations table

  filtredByAuthorisationDate =
    search?.dateStart || search?.dateEnd
      ? table?.filter((row) => {
          const START = changeDaysjsFormat(search?.dateStart);
          const END = changeDaysjsFormat(search?.dateEnd);
          return search == null
            ? row
            : tableName === "authorisations" || tableName === "leaves-history"
            ? dayjs(row?.date).isBetween(dayjs(START), END) ||
              dayjs(row?.date).isBetween(dayjs(START), END) ||
              dayjs(row?.date).isSame(dayjs(START), END) ||
              dayjs(row?.date).isSame(dayjs(END), START)
              ? row
              : ""
            : "";
        })
      : table;

  filteredByNameAuthorisationTable = search?.name
    ? filtredByAuthorisationDate?.filter((row) => {
        return search == null
          ? row
          : tableName === "authorisations" || tableName === "leaves-history"
          ? row.user.fullName
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.name?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.dateStart || search?.dateEnd
    ? filtredByAuthorisationDate
    : table;

  // filter in users table
  fileredByDepartment = search?.departmentID
    ? table?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row?.department?._id.includes(search?.departmentID)
            ? row
            : ""
          : "";
      })
    : table;

  filteredByStatus = search?.status
    ? fileredByDepartment?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.status
              .trim()
              .toLowerCase()
              .includes(search?.status?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.departmentID
    ? fileredByDepartment
    : table;

  filteredByJobTitle = search?.jobTitle
    ? filteredByStatus?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.jobTitle
              .trim()
              .toLowerCase()
              .includes(search?.jobTitle?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : table;

  fileredByAddress = search?.address
    ? filteredByJobTitle?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row?.address
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.address?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.jobTitle
    ? filteredByJobTitle
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : table;

  filteredByName = search?.name
    ? fileredByAddress?.filter((row) => {
        return search == null
          ? row
          : tableName === "users"
          ? row.fullName
              ?.trim()
              ?.toLowerCase()
              ?.includes(search?.name?.trim().toLowerCase())
            ? row
            : ""
          : "";
      })
    : search?.address
    ? fileredByAddress
    : search?.jobTitle
    ? filteredByJobTitle
    : search?.status
    ? filteredByStatus
    : search?.departmentID
    ? fileredByDepartment
    : search?.leaveTypeID
    ? filtredByLeaveType
    : table;

  return tableName === "leaves" || tableName === "leaves-history"
    ? filteredByNameLeavesTable
    : tableName === "authorisations" || tableName === "leaves-history"
    ? filteredByNameAuthorisationTable
    : tableName === "users"
    ? filteredByName
    : Rows;
};

export default Search;
