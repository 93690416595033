// Get avatar by gender

import ImAvatar from "../assets/images/avatar.png";
import ImAvatarMale from "../assets/images/avatar-male.png";
import ImAvatarFemale from "../assets/images/avatar-female.png";
export const avatarByGender = (avatar, gender) => {
  if (avatar !== null) {
    return avatar;
  }
  if (gender === "Male") {
    return ImAvatarMale;
  }
  if (gender === "Female") {
    return ImAvatarFemale;
  }
  return ImAvatar;
};
