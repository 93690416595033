const SortBy = (table, sortby) => {
  let sortedTable = [];
  for (let i = 0; i < sortby.length; i++) {
    const cell = table?.find((el) => el[0] === sortby[i]);
    sortedTable.push(cell ? cell : "");
  }
  return sortedTable;
};

export default SortBy;
