import React from "react";

export const changeOneDateFormat = (DateToChange) => {
  const DATE = new Date(DateToChange);

  const Day = DATE.toLocaleString("en-US", { day: "2-digit" });

  const Month = DATE.toLocaleString("en-US", { month: "long" })
    .toString()
    .slice(0, 3);
  const Year = DATE.toLocaleString("en-US", { year: "numeric" });

  return Day + " " + Month + " " + Year;
};
